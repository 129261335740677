.popup-index-code {
    width: (167rem / 100);
    height: (167rem / 100);
    margin: 0 auto (37rem / 100);
}

.popup-index-msg {
    font-size: (22rem / 100);
    font-family: AlibabaPuHuiTi-Regular, AlibabaPuHuiTi;
    font-weight: 500;
    color: #939EC6;
    line-height: (22rem / 100);
}