.detail-index-top-right {
  .resources-detail-top-title {
    width: (934rem / 100);
    height: (34rem / 100);
    font-size: (22rem / 100);
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #273143;
    line-height: (34rem / 100);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-bottom: (19rem / 100);
  }

  .resources-detail-top-des {
    margin-bottom: (92rem / 100);

    &-ul {
      width: 100%;
      font-size: (16rem / 100);
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #273143;
      line-height: (22rem / 100);
      color: #232323;

      &-li {
        height: (24rem / 100);
        margin-bottom: (10rem / 100);
        display: flex;
        align-items: center;
      }
    }
  }
}

.detail-index-bottom-content {
  padding: 0 (16rem / 100) (146rem / 100) 0;

  &-top {
    width: (1200rem / 100);
    height: (100rem / 100);
    background: url("https://image.bookgo.com.cn/webculture/jm/detail/detail_bottom_top_bg.png")
      no-repeat;
    background-size: 100% 100%;
    box-shadow: 0 5px 15px 0 rgba(207, 181, 140, 0.8);
    display: flex;
    align-items: center;
    padding-left: (30rem / 100);
    margin-bottom: (28rem / 100);

    &-title {
      width: (140rem / 100);
      height: (31rem / 100);
      text-align: center;
      line-height: (31rem / 100);
      font-size: (20rem / 100);
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #ffffff;
      text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3);
      background: url("https://image.bookgo.com.cn/webculture/jm/detail/detail_title_bg.png")
        no-repeat;
      background-size: 100% 100%;
    }
  }

  .detail-index-bottom-content-bottom-box {
    margin-bottom: (44rem / 100);
    padding: 0 (30rem / 100);

    &-top {
      display: flex;
      align-items: center;
      font-size: (16rem / 100);
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #273143;
      line-height: (16rem / 100);
      margin-bottom: (15rem / 100);

      &-icon {
        width: (9rem / 100);
        height: (16rem / 100);
        margin-right: (7rem / 100);
      }
    }

    &-bottom {
      padding-left: (16rem / 100);
      font-size: (14rem / 100);
      font-family: PingFangSC-Regular, PingFang SC;
      color: #273143;
      line-height: (28rem / 100);
    }
  }
}
.addPdfStyle{
  width: 1.49rem;
  height: 0.5rem;
  background: #761F1E;
  border-radius: 0.25rem;
  font-size: 0.16rem;
  color: white;
  line-height: 0.5rem;
  text-align: center;
  margin-top: 0.3rem;
}