.el-dialog {
    position: relative;
    margin: 0 auto 50px;
    border-radius: (5rem / 100);
    box-shadow: 0 1px 3px rgba(0, 0, 0, .3);
    box-sizing: border-box;
    width: (580rem / 100);
    border: (5rem / 100) solid #939EC6 !important;
}

.el-dialog__header {
    padding: (27rem / 100) (26rem / 100) (16rem / 100);
    border-bottom: (1rem / 100) solid #DFDFDF;
}

.el-dialog__headerbtn {
    position: absolute;
    top: (27rem / 100);
    right: (26rem / 100);
    padding: 0;
    background: 0 0;
    border: none;
    outline: 0;
    cursor: pointer;
    font-size: (20rem / 100);
}

.el-dialog--center .el-dialog__body {
    text-align: center;
    padding: (30rem / 100) (25rem / 100) (50rem / 100);
}