// 最外层盒子样式
%out-box {
  // width: 19.2rem;
  position: relative;
}

// 1920设计稿，所有的值除以100；

// 内层盒子
%inside-box {
  width: (1200rem / 100);
  margin: 0 auto;
}
.container {
  background: url("https://image.bookgo.com.cn/%20webculture/jm/bg/bg_body.jpg");
  padding-top: 0.9rem;
}
.detail-index-bg {
  @extend %out-box;
  background: url("https://image.bookgo.com.cn/%20webculture/jm/bg/bg_body.jpg");
  padding-bottom: (160rem / 100);
  // margin-top: 0.2rem;
  
  .detail-index {
    @extend %inside-box;
    padding-top: (78rem / 100);

    &-crumbs {
      display: flex;
      align-items: center;
      font-size: (14rem / 100);
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      line-height: (20rem / 100);
      margin-bottom: (28rem / 100);

      &-up {
        color: #273143;
        margin-right: 0.1rem;
      }

      &-this {
        color: #761f1e;
        margin-left: 0.1rem;
      }
    }

    &-top {
      width: 100%;
      min-height: (420rem / 100);
      background: #ffffff;
      margin-bottom: (30rem / 100);
      border-radius: (5rem / 100);
      padding: (30rem / 100);
      display: flex;
      position: relative;

      &-left {
        width: (612rem / 100);
        height: (360rem / 100);
        margin-right: (40rem / 100);
        box-sizing: border-box;
        position: relative;

        .listState{
          position: absolute;
          left: 0rem;
          top: 0rem;
          display: flex;
          align-items: center;
          padding: 0.035rem 0.098rem 0.035rem 0.098rem;
          color: white;
        }
        .current1{
            .live-ul-li-banner-icon{
              width: (17.65rem / 100);
              height: (15rem / 100);
              // float: left;
              margin-right: (10rem / 100);
          
            }
            background: #761F1E;
        }
        .current2{
            background: #CD9E56;
        }
        .current3{
            background: #000000;
        }
        .listStateFont{
            font-size: 0.15rem;
            display: flex;
          align-items: center;
          //width: (98rem / 100);
          height: (35rem / 100);
        }
        &-button{
          //position:absolute;
          //top: (135rem / 100);
          //left:(235rem / 100);
          margin: auto;
	        position: absolute;
	        top: 0;
        	left: 0; 
        	right: 0;
        	bottom: 0;
          width: (70rem / 100);
          height: (70rem / 100);
          cursor:pointer;
          &-icon{
            width: (70rem / 100);
            height: (70rem / 100);
          }
        }
        &-background{
          background-color: #273143;
          height: 100%;
          width: 100%;
          position: absolute;
          top: 0;
          opacity: 50%;
          overflow: hidden;
        }
        .GeneratePlayback{
          //position:absolute;
          //top: (152rem / 100);
          //left:(220rem / 100);
          width:(120rem / 100);
          height:(33rem / 100);
          margin: auto;
	        position: absolute;
	        top: 0;
        	left: 0; 
        	right: 0;
        	bottom: 0;
          font-size: (20rem / 100);
          color:#ffffff;
        }
        .livenull{
          position:absolute;
        top: (152rem / 100);
        left:(220rem / 100);
        width:(173rem / 100);
        height:(33rem / 100);
        font-size: (20rem / 100);
        color:#ffffff;
      }
        
        &-button1{
          //position:absolute;
          //top: (135rem / 100);
          //left:(250rem / 100);
          margin: auto;
	        position: absolute;
	        top: 0;
        	left: 0; 
        	right: 0;
        	bottom: 0;
          width: (70rem / 100);
          height: (70rem / 100);
          cursor:pointer;
          &-icon{
            width: (70rem / 100);
            height: (70rem / 100);
          }
        }
      }

      &-right {
        width: (510rem / 100);
      }

      .detail-index-top-content-headPic {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: (33rem / 100);
      }

      .detail-index-top-content-footerPic {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: (33rem / 100);
      }
    }

    &-top1 {
      min-height: (320rem / 100);

      .detail-index-top-left {
        width: (160rem / 100);
        height: (260rem / 100);
        margin-right: (30rem / 100);
      }
    }

    &-bottom {
      @extend %inside-box;

      &-content {
        width: (1200rem / 100);
        background: #ffffff;
        position: relative;

        .detail-index-top-content-footerPic {
          position: absolute;
          left: 0;
          bottom: 0;
          width: 100%;
          height: (33rem / 100);
        }
      }

      &-bread {
        font-size: 0.14rem;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #273143;
        margin-bottom: 0.33rem;
        display: flex;
        align-items: center;

        a {
          margin-right: 4px;
        }

        span {
          color: #761f1e;
        }
      }
    }
  }

  .detail-index-bottom-left-content {
    padding-left: 0.26rem;
    padding-bottom: (26rem / 100);
    position: relative;

    &-pic {
      position: absolute;
      width: 0.14rem;
      height: 0.3rem;
      left: 0;

      img {
        width: 100%;
        height: 100%;
      }
    }

    &-title {
      width: (100%);

      font-size: 0.22rem;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #273143;
      line-height: 0.34rem;
      margin-bottom: (16rem / 100);
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
    }

    &-des {
      margin-bottom: (16rem / 100);

      &-ul {
        font-size: 0.14rem;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #56657f;
        line-height: 0.2rem;
        margin-bottom: (20rem / 100);

        &-li {
          float: left;
          margin-right: (30rem / 100);
        }

        &-li:last-of-type {
          margin-right: 0;
        }
      }
    }

    &-linePic {
      width: 100%;

      img {
        width: 100%;
      }
    }
  }

  .detail-index-bottom-left-bottom-summary {
    font-size: (18rem / 100);
    font-family: AlibabaPuHuiTi-Regular, AlibabaPuHuiTi;
    font-weight: 300;
    line-height: (31rem / 100);
    color: #818181;
  }
}

.downFile-all{
  width: 12rem;
  color: #273143;
  margin: 0.43rem;
  margin: auto;
  margin-top: 0.43rem;
}
.file-title{
font-size:0.16rem
}
.file-title img{
display: inline-block;
vertical-align: middle;
}
.file-list{
font-size: 0.14rem;
margin-top: 0.12rem;
margin-bottom: 0.14rem;
font-weight: 500;
}
.downButton{
color: #9E6362;
margin-left: 0.15rem;
cursor: pointer;
}
.downSize{
color:rgb(79, 104, 146)
}